<template>
  <!-- 布局页面 -->
  <div>
    <div class="page-demo-bar">
      <router-link
        class="demo-bar-item"
        active-class="active"
        to="/components-demo"
        >组件</router-link
      >
      <router-link class="demo-bar-item" active-class="active" to="/page-demo"
        >演示页面</router-link
      >
    </div>
    <div class="page-content">
      <router-view />
    </div>
    <div class="page-bottom">
      <img src="@/assets/BA-logo.png" class="BA-logo" />
      <a class="BA-number" href="https://beian.miit.gov.cn/" target="_blank"
        >湘ICP备18006858号-1</a
      >
    </div>
  </div>
</template>
 
<script>
export default {
  name: "sy-layout",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
 
<style scoped>
.page-demo-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #eee;
}

.demo-bar-item {
  padding: 0 20px;
  color: #333;
  font-size: 18px;
  text-decoration: none;
}

.demo-bar-item:hover {
  color: #000;
}

.active {
  color: #000;
  font-weight: bold;
}

.page-content {
  height: calc(100vh - 100px);
}

.page-bottom {
  width: 100%;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eee;
}

.BA-logo{
  width: 16px;
  height: 17px;
  margin-right: 10px;
}

.BA-number{
  text-decoration: none;
  color: #000;
}
</style>