import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import layout from '../layout/layout.vue'

const routes = [
    {
        path: '/',
        component: layout,
        children: [
            { path: '/components-demo', component: () => import('@/views/components-demo/components-demo.vue') },
            { path: '/page-demo', component: () => import('@/views/page-demo/page-demo.vue') },
        ]
    }
]

const router = new VueRouter({
    routes
})

export default router