import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(store)
Vue.use(ElementUI)

// PDF打印
import Print from 'vue-print-nb'
Vue.use(Print);

// 百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'HNjGnakQyY8ZzCx8QCg433QmfLsnfN88'
})



new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
